<template>
    <div id="summary" class="column is-3">
        <div class="box b-shdw-3">
            <div class="level-item" oncontextmenu="return false;">
                <picture>
                    <source srcset="../../assets/images/icons/quote-summary-icon.webp" type="image/webp"/>
                    <source srcset="../../assets/images/icons/quote-summary-icon.png" type="image/png"/>
                    <img src="../../assets/images/icons/quote-summary-icon.webp" alt="Quote Summary" width="70" loading="lazy" />
                </picture>
            </div>
            <h4 id="summary-title" class="title is-4">Quote Summary</h4>
            <hr>
            <slot/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BaseSummaryContainer"
    }
</script>

<style lang="scss" scoped>
    #summary-title {
        text-align: center;
        color: $c2g_orange;
        margin-bottom: 10px;
    }
    #summary {
        position: -webkit-sticky;
        position: sticky !important;
        top: 1rem;
    }
    hr {
        margin: 0 0 2px 0;
        border-bottom: 3px solid $c2g_orange;
    }
</style>