export const policyUsefulFunctions = {
    computed: {
        // Get ages from quote
        ages() {
            let ages = [];
            for(let i = 1; i < 8; i++) {
                if(this.policy.quote['age' + i] > 0) {
                    ages.push(this.policy.quote['age' + i]);
                }
            }
            return ages;
        },
        // Format start date to UK style
        startDate() {
            return this.$moment(this.policy.quote.start_date).format('DD/MM/YYYY');
        },
        // Format end date to UK style
        endDate() {
            return this.$moment(this.policy.quote.end_date).format('DD/MM/YYYY');
        }
    }
};